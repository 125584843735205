import React from 'react';

import { LabeledInput, InputProps } from 'components/Input/LabeledInput';

import { SearchInputElements } from './elements';

const { Container } = SearchInputElements;

export const SearchInput: React.FC<
  Omit<InputProps, 'label' | 'name' | 'onChange'> & { onChange: (v: string) => void }
> = ({ value, onChange }) => (
  <Container>
    <LabeledInput
      name="offer-search-input"
      label="Przeszukaj ofertę"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </Container>
);
