import React from 'react';

import { RequestBoxElements } from './elements';

const { Container, Link, Text } = RequestBoxElements;

export const RequestBox: React.FC = () => (
  <Container>
    <Text>Nie znalazłeś tego czego szukasz? </Text>
    <span>
      Wyślij nam <Link href="mailto:biuro@bachem.pl">zapytanie</Link>!
    </span>
  </Container>
);
