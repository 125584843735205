import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.color.gray[200]}50;
    display: flex;
    flex-wrap: wrap;
  `
);

const Color = styled.div<{ color: string }>(
  ({ color, theme }) => css`
    align-items: center;
    background: ${color};
    border-radius: ${theme.radius.main};
    box-shadow: ${theme.shadow.depth2};
    display: flex;
    height: 50px;
    justify-content: center;
    margin: 0 ${theme.spacing.mid} ${theme.spacing.mid} 0;
    width: 50px;

    svg {
      fill: ${theme.color.gray[200]};
    }
  `
);

export const NAME_MAP = {
  black1: 'BK 10 B (czarny)',
  blue1: 'BE 28 M (niebieski)',
  blue2: 'BE 27 M (niebieski)',
  blue3: 'BE 06 B (niebieski)',
  blue4: 'BE 05 B (niebieski)',
  red1: 'RD 09 B (czerwony)',
  red2: 'RD 08 B (czerwony)',
  red3: 'RD 07 B (czerwony)',
  gray1: 'GY 22 M (szary)',
  yellow1: 'YW 01 B (żółty)',
  yellow2: 'YW 02 B (żółty)',
  green1: 'GN 25 N (zielony)',
  green2: 'GN 04 B (zielony)',
  green3: 'GN 24 B (zielony)',
  orange1: 'OE 03 B (pomarańczowy)'
};

const COLOR_MAP = {
  black1: '#000',
  blue1: '#3b547c',
  blue2: '#1081c3',
  blue3: '#1a79cc',
  blue4: '#0066a8',
  red1: '#9f2829',
  red2: '#ad222a',
  red3: '#ca3e27',
  gray1: '#8e9293',
  yellow1: '#b8a200',
  yellow2: '#c7a300',
  green1: '#177e65',
  green2: '#009d23',
  green3: '#12745d',
  orange1: '#cf5222'
};

export const ColorPalette: React.FC<{ colors: string[] }> = ({ colors }) => (
  <Container>
    <ReactTooltip place="bottom" effect="solid" />
    {colors?.map((color) => (
      <Color key={color} data-tip={NAME_MAP[color]} color={COLOR_MAP[color]} />
    ))}
  </Container>
);
