import React from 'react';

import { HeadingDescription } from 'components/Typography';
import { Offer as OfferType } from 'database';
import Layout from 'layouts';
import { useData, useSearchQueryReader } from 'lib/hooks';
import { SEO } from 'SEO';

import { Conspect } from './components/Conspect';
import { Delivery } from './components/Delivery';
import { Finish } from './components/Finish';
import { Methods } from './components/Methods';
import { OfferItem } from './components/OfferItem/OfferItem';
import { RequestBox } from './components/RequestBox';
import { SearchInput } from './components/SearchInput';
import { OfferElements } from './elements';
import { generateSearchString } from './utils';

const { Container, PageHeading, List, HeadingSubtext } = OfferElements;

export const OfferPage: React.FC = () => {
  const { offer, products } = useData();
  const [{ search }, onQueryChange] = useSearchQueryReader<{ search: string }>();

  const isMatch = (item: OfferType.Data): boolean => {
    const searchString = generateSearchString(
      item,
      products.filter((p) => p.categories.includes(item.slug))
    );

    if (!search) return true;
    if (search.trim().length <= 2) return true;

    return searchString.includes(search.trim().toLowerCase());
  };

  return (
    <Layout>
      <SEO title="Oferta" canonicalHref="/oferta/" />
      <Conspect />
      <Container>
        <PageHeading>Oferta</PageHeading>
        <HeadingDescription>
          W naszej ofercie znaleźć można wszelkiego rodzaju wyroby z metalu oraz usługi jego obróbki.
        </HeadingDescription>
        <HeadingSubtext>
          Zapytania i zamówienia przyjmujemy wyłącznie na adres e-mail. Napisz do nas na{' '}
          <a href="mailto:biuro@bachem.pl">biuro@bachem.pl</a>.
        </HeadingSubtext>
        <SearchInput value={search} onChange={(value: string) => onQueryChange({ search: value })} />
        <List>
          {offer
            .filter((item) => products.find((product) => product.categories.includes(item.slug)))
            .map((item) => (
              <OfferItem
                key={item.id}
                title={item.title}
                description={item.shortDescription}
                link={`/oferta/${item.slug}/`}
                faded={!isMatch(item)}
              />
            ))}
          <RequestBox />
        </List>
        <Methods />
        <Finish />
        <Delivery />
      </Container>
    </Layout>
  );
};
