import styled, { css } from 'styled-components';

const Heading = styled.h3(
  ({ theme }) => css`
    color: ${theme.color.blue[200]};
    margin-top: ${theme.spacing.huge};
    ${theme.font(16, 'BOLD')};

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      ${theme.font(20, 'BOLD')};
    }
  `
);

const SubHeading = styled.h4(
  ({ theme }) => css`
    color: ${theme.color.gray[100]};
    margin-bottom: ${theme.spacing.mid};
    margin-top: ${theme.spacing.mid};
    ${theme.font(16, 'SEMIBOLD')};

    a {
      color: ${theme.color.blue[400]};
      &:hover {
        text-decoration: underline;
      }
    }
  `
);

export const FinishElements = {
  Heading,
  SubHeading
};
