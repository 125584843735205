import { Link as LinkBase } from 'gatsby';
import styled, { css } from 'styled-components';

const Container = styled.div<{ faded: boolean }>(
  ({ theme, faded }) => css`
    background: ${theme.color.blue[400]};
    display: grid;
    grid-gap: ${theme.spacing.big};
    opacity: ${faded ? 0.3 : 1};
    padding: ${theme.spacing.bigger};
    width: 100%;

    ${theme.onScreen('SMALL_DESKTOP')} {
      grid-gap: ${theme.spacing.huge};
    }
  `
);

const Title = styled.h2(
  ({ theme }) => css`
    ${theme.font(20, 'SEMIBOLD')};
    color: ${theme.color.white};

    &:hover {
      text-decoration: underline;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      font-size: 1.3rem;
      height: 3.3rem;
    }
  `
);

const Description = styled.p(
  ({ theme }) => css`
    color: ${theme.color.white};
    font-size: 0.875rem;
  `
);

const Link = styled(LinkBase)(
  ({ theme }) => css`
    align-self: flex-end;
    color: ${theme.color.blue[100]};
    font-weight: 600;

    &:hover {
      color: ${theme.color.blue[300]};
    }
  `
);

export const OfferItemElements = {
  Container,
  Title,
  Description,
  Link
};
