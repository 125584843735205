import { Offer, Product } from 'database';

export function generateSearchString(item: Offer.Data, products: Product.Data[]): string {
  const productsString = products.reduce((acc, curr) => {
    const technologies = curr.technologies.map((t) => Product.Technology[t]);
    return `${acc}${curr.name}${curr.description}${Product.Plate[curr.plate]}${technologies}`;
  }, '');

  const itemString = `${item.title}${item.shortDescription}${item.description}`;

  return `${itemString}${productsString}`.toLowerCase();
}
