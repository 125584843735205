import styled, { css } from 'styled-components';

const Table = styled.table(
  ({ theme }) => css`
    margin-top: ${theme.spacing.big};

    tbody tr:nth-child(odd) {
      background: ${theme.color.gray[300]}80;
    }

    td,
    th {
      padding: ${theme.spacing.mid};
    }

    th {
      border-bottom: 2px solid ${theme.color.gray[200]};
      text-align: left;
      color: ${theme.color.gray[100]};
      ${theme.font(16, 'BOLD')};
    }

    tfoot {
      ${theme.font(16, 'SEMIBOLD')};

      td {
        a {
          color: ${theme.color.blue[400]};

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  `
);

export const DeliveryElements = {
  Table
};
