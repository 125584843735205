import { ChangeEvent, useState } from 'react';
import styled, { css } from 'styled-components';

export const Input = styled.input(
  ({ theme }) => css`
    border: 0;
    box-shadow: 0;
    color: ${theme.color.blue[200]};
    font-size: 1.15rem;
    outline: none;
    padding: ${theme.spacing.mid};
  `
);

type UseInput = () => {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

export const useInput: UseInput = () => {
  const [value, setValue] = useState<ReturnType<UseInput>['value']>('');
  const onChange: ReturnType<UseInput>['onChange'] = (e) => {
    setValue(e.target.value);
  };

  return { value, onChange };
};
