import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';

import { ConspectElements } from './elements';

const { Container, List, Heading } = ConspectElements;

export const Conspect: React.FC = () => (
  <Container>
    <List>
      <Heading>
        <Link to="/oferta/">Oferta</Link>
      </Heading>
      <li>
        <AnchorLink to="/oferta/#metody-wykonania">Metody wykonania</AnchorLink>
      </li>
      <li>
        <AnchorLink to="/oferta/#wykonczenie">Wykończenie</AnchorLink>
      </li>
      <li>
        <AnchorLink to="/oferta/#transport-i-pakowanie">Koszt transportu i pakowania</AnchorLink>
      </li>
    </List>
  </Container>
);
