import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing.huge};
    width: 100%;
  `
);

export const SearchInputElements = {
  Container
};
