import styled, { css } from 'styled-components';

const Content = styled.section(
  ({ theme }) => css`
    display: flex;
    margin: ${theme.spacing.big} 0;
    width: 100%;

    &:first-child {
      margin: 0;
    }
  `
);

const P = styled.p(
  ({ theme }) => css`
    margin: ${theme.spacing.mid} 0;
    text-align: justify;
  `
);

const Img = styled.img`
  display: block;
  filter: contrast(100%) grayscale(20%) hue-rotate(90deg) opacity(0.5);
  height: 300px;
  object-fit: cover;
  width: 400px;
`;

const ImgWrapper = styled.div(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('SMALL_DESKTOP')} {
      background: ${theme.color.blue[400]};
      display: block;
      height: 300px;
      margin-right: ${theme.spacing.bigger};
    }
  `
);

const Heading = styled.h3(
  ({ theme }) => css`
    color: ${theme.color.blue[200]};
    grid-column: 2;
    margin-top: 0;
    ${theme.font(16, 'BOLD')};

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      ${theme.font(20, 'BOLD')};
    }

    &:before {
      background: ${theme.color.blue[400]};
      content: '';
      height: 5px;
      width: 10px;
    }
  `
);

export const MethodsElements = {
  Content,
  Heading,
  P,
  Img,
  ImgWrapper
};
