import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    background: ${theme.color.white};
    display: flex;
    padding: ${theme.spacing.mid};

    ${theme.onScreen('LARGE_PHONE')} {
      flex-direction: column;
      padding: ${theme.spacing.bigger};
    }
  `
);

const Text = styled.p(
  ({ theme }) => css`
    color: ${theme.color.blue[200]};
    font-weight: 600;
    margin-right: ${theme.spacing.mid};

    ${theme.onScreen('LARGE_PHONE')} {
      font-size: 1.5rem;
      margin-bottom: ${theme.spacing.bigger};
      margin-right: 0;
    }
  `
);

const Link = styled.a`
  color: ${({ theme }) => theme.color.blue[400]};
`;

export const RequestBoxElements = {
  Container,
  Text,
  Link
};
