import { Link as LinkBase } from 'gatsby';
import React from 'react';

import { OfferItemElements } from './elements';

const { Container, Title, Description, Link } = OfferItemElements;

interface OfferItemProps {
  title: string;
  description: string;
  link: string;
  faded: boolean;
}

export const OfferItem: React.FC<OfferItemProps> = ({ title, description, link, faded }) => (
  <Container faded={faded}>
    <LinkBase to={link}>
      <Title>{title}</Title>
    </LinkBase>
    <Description>{description}</Description>
    <Link to={link}>Zobacz ofertę</Link>
  </Container>
);
