import React from 'react';

import { HeadingDescription, PageHeading } from 'components/Typography';

import { DeliveryElements } from './elements';

const { Table } = DeliveryElements;

interface DeliveryTableRow {
  weight: string;
  price: string;
  priceOnDelivery: string;
  delivery: string;
}

const TABLE_DATA: DeliveryTableRow[] = [
  {
    weight: '1-5 kg',
    price: '23,00 zł',
    priceOnDelivery: '26,00 zł',
    delivery: 'Inpost Polska / DPD'
  },
  {
    weight: '6-10 kg',
    price: '25,00 zł',
    priceOnDelivery: '28,00 zł',
    delivery: 'Inpost Polska / DPD'
  },
  {
    weight: '11-15 kg',
    price: '28,00 zł',
    priceOnDelivery: '31,00 zł',
    delivery: 'Inpost Polska / DPD'
  },
  {
    weight: '16-20 kg',
    price: '30,00 zł',
    priceOnDelivery: '33,00 zł',
    delivery: 'Inpost Polska / DPD'
  },
  {
    weight: '21-30 kg',
    price: '33,00 zł',
    priceOnDelivery: '36,00 zł',
    delivery: 'Inpost Polska / DPD'
  }
];

export const Delivery: React.FC = () => (
  <>
    <PageHeading variant="sub" id="transport-i-pakowanie">
      Koszt transportu i pakowania
    </PageHeading>
    <HeadingDescription>Sprawdź sposób i koszt dostawy.</HeadingDescription>

    <Table>
      <thead>
        <tr>
          <th>Waga</th>
          <th>Cena netto</th>
          <th>Cena netto za pobraniem</th>
          <th>Przewoźnik</th>
        </tr>
      </thead>
      <tbody>
        {TABLE_DATA.map((row) => (
          <tr key={row.weight}>
            <td>{row.weight}</td>
            <td>{row.price}</td>
            <td>{row.priceOnDelivery}</td>
            <td>{row.delivery}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={Object.keys(TABLE_DATA[0]).length}>Przy zamówieniach powyżej 1500 zł transport gratis.</td>
        </tr>
        <tr>
          <td colSpan={Object.keys(TABLE_DATA[0]).length}>Doręczenie sobota: do ceny transportu + 15 zł.</td>
        </tr>
        <tr>
          <td colSpan={Object.keys(TABLE_DATA[0]).length}>
            Wysyłka za granicę spedycją DPD zgodnie z{' '}
            <a
              href="https://www.dpd.com/pl/pl/oferta-dla-firm/warunki-wysylki/warunki-wykonywania-uslug-miedzynarodowych/"
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              cennikiem{' '}
            </a>
            dla kontrahentów zagranicznych.
          </td>
        </tr>
      </tfoot>
    </Table>
  </>
);
