import styled, { css } from 'styled-components';

const Container = styled.nav(
  ({ theme }) => css`
    display: none;

    @media (min-width: 1720px) {
      background: ${theme.color.gray[300]}80;
      display: block;
      left: ${theme.spacing.big};
      padding: ${theme.spacing.bigger};
      position: fixed;
      top: calc(${theme.size.menuHeight} + ${theme.spacing.big});
    }
  `
);

const List = styled.ul(
  ({ theme }) => css`
    ${theme.font(14, 'SEMIBOLD')}

    > li {
      margin: ${theme.spacing.small} 0;

      a {
        color: ${theme.color.gray[200]};

        &:hover {
          color: ${theme.color.blue[400]};
        }
      }
    }
  `
);

const Heading = styled.h1(
  ({ theme }) => css`
    color: ${theme.color.blue[200]};
    ${theme.font(18, 'BOLD')};
  `
);

export const ConspectElements = {
  Container,
  List,
  Heading
};
