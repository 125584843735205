import React from 'react';

import { HeadingDescription, PageHeading } from 'components/Typography';
import { useData } from 'lib/hooks';

import { OfferElements } from '../../elements';
import { MethodsElements } from './elements';

const { Listing } = OfferElements;
const { Content, Heading, P, Img, ImgWrapper } = MethodsElements;

export const Methods: React.FC = () => {
  const { methods } = useData();

  return (
    <>
      <PageHeading variant="sub" id="metody-wykonania">
        Metody wykonania
      </PageHeading>
      <HeadingDescription>Oferujemy wiele metod wykonania naszych produktów.</HeadingDescription>
      {methods.map((method) => (
        <Content key={method.title}>
          <ImgWrapper>
            <Img src={method.img.childImageSharp.fluid.src} alt={`Obraz metody ${method.title}`} />
          </ImgWrapper>
          <div>
            <Heading>{method.title}</Heading>
            {method.description.map((p) => (
              <P key={p}>{p}</P>
            ))}
            <Listing>
              <h1>Specyfikacja</h1>
              {method.spec.map((s) => (
                <li key={s}>{s}</li>
              ))}
            </Listing>
          </div>
        </Content>
      ))}
    </>
  );
};
