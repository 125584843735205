import React from 'react';

import { HeadingDescription, PageHeading } from 'components/Typography';
import { ColorPalette, NAME_MAP } from 'views/SingleProductPage/ColorPalette';

import { OfferElements } from '../../elements';
import { DeliveryElements } from '../Delivery/elements';
import { FinishElements } from './elements';

const { Listing } = OfferElements;
const { Table } = DeliveryElements;
const { Heading, SubHeading } = FinishElements;

export const Finish: React.FC = () => (
  <>
    <PageHeading variant="sub" id="wykonczenie">
      Wykończenie
    </PageHeading>
    <HeadingDescription>Elementy wykończeniowe i estetyka.</HeadingDescription>
    <Heading>Rodzaje i grubości materiału</Heading>
    <Table>
      <thead>
        <tr>
          <th>Aluminium</th>
          <th>Stal kwasoodporna</th>
          <th>Stal nierdzewna</th>
          <th>Mosiądz</th>
          <th>Laminat</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Listing>
              <li>0,5 mm</li>
              <li>0,8 mm</li>
              <li>1,0 mm</li>
              <li>1,5 mm</li>
              <li>2,0 mm</li>
              <li>2,5 mm</li>
              <li>3,0 mm</li>
              <li>4,0 mm</li>
            </Listing>
          </td>
          <td>
            <Listing>
              <li>0,5 mm</li>
              <li>0,8 mm</li>
              <li>1,0 mm</li>
              <li>1,2 mm</li>
              <li>1,5 mm</li>
              <li>2,0 mm</li>
              <li>3,0 mm</li>
            </Listing>
          </td>
          <td>
            <Listing>
              <li>0,4 mm</li>
              <li>0,5 mm</li>
              <li>0,8 mm</li>
              <li>1,0 mm</li>
              <li>1,5 mm</li>
              <li>2,0 mm</li>
            </Listing>
          </td>
          <td>
            <Listing>
              <li>0,5 mm</li>
              <li>0,8 mm</li>
              <li>1,0 mm</li>
              <li>1,5 mm</li>
              <li>2,0 mm</li>
              <li>2,5 mm</li>
              <li>3,0 mm</li>
            </Listing>
          </td>
          <td>
            <Listing>
              <li>0,25 mm</li>
              <li>0,5 mm</li>
              <li>0,8 mm</li>
              <li>1,3 mm</li>
              <li>1,6 mm</li>
              <li>2,5 mm</li>
              <li>3,0 mm</li>
            </Listing>
          </td>
        </tr>
      </tbody>
    </Table>
    <Heading>Kolory</Heading>
    <SubHeading>Blacha anodowana Polychromal:</SubHeading>
    <ColorPalette colors={Object.keys(NAME_MAP)} />
    <SubHeading>
      Kolory blachy trawionej / grawerowanej według podstawowej{' '}
      <a target="_blank" rel="noreferrer" href="https://farbyspecjalistyczne.pl/wzornik-kolorow-ral/">
        palety RAL
      </a>
      .
    </SubHeading>
    <Heading>Rodzaje rożków</Heading>
    <Table>
      <thead>
        <tr>
          <th>Rożkarka mała</th>
          <th>Rożkarka nożna</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Listing>
              <li>R 1,5 mm</li>
              <li>R 3,0 mm</li>
              <li>R 5,0 mm</li>
              <li>R 7,0 mm</li>
            </Listing>
          </td>
          <td>
            <Listing>
              <li>R 3,0 mm</li>
              <li>R 5,0 mm</li>
              <li>R 6,0 mm</li>
              <li>R 8,0 mm</li>
            </Listing>
          </td>
        </tr>
      </tbody>
    </Table>
    <Heading>Rodzaje otworów</Heading>
    <Table>
      <thead>
        <tr>
          <th>Maszynka nożna</th>
          <th>Stemple pojedyncze</th>
          <th>Wykrojniki kółek</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <Listing>
              <li>Ø 2,0 mm</li>
              <li>Ø 2,5 mm</li>
              <li>Ø 2,6 mm</li>
              <li>Ø 3,0 mm</li>
              <li>Ø 3,1 mm</li>
              <li>Ø 3,5 mm</li>
              <li>Ø 4,0 mm</li>
              <li>Ø 4,2 mm</li>
              <li>Ø 4,5 mm</li>
              <li>Ø 5,0 mm</li>
              <li>Ø 5,5 mm</li>
              <li>Ø 6,0 mm</li>
              <li>Ø 8,0 mm</li>
            </Listing>
          </td>
          <td>
            <Listing>
              <li>Ø 2,0 mm</li>
              <li>Ø 3,0 mm</li>
              <li>Ø 3,5 mm</li>
              <li>Ø 4,0 mm</li>
              <li>Ø 4,2 mm</li>
              <li>Ø 4,5 mm</li>
              <li>Ø 5,0 mm</li>
              <li>Ø 5,5 mm</li>
              <li>Ø 6,0 mm</li>
              <li>Fasolka 2 x 5,5 mm</li>
            </Listing>
          </td>
          <td>
            <Listing>
              <li>Ø 14,0 mm</li>
              <li>Ø 15,0 mm</li>
              <li>Ø 25,0 mm</li>
              <li>Ø 30,0 mm</li>
              <li>Ø 35,0 mm</li>
            </Listing>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={4}>W przypadku obróbki laserowej jesteśmy w stanie wykonać dowolny otwór.</td>
        </tr>
      </tfoot>
    </Table>
    <Heading>Rodzaje taśmy</Heading>
    <Listing>
      <li>taśma 3M dwustronna samoprzylepna </li>
      <ol>3M 467MP</ol>
      <ol>ST 23101</ol>
      <ol>3M 9448A</ol>
      <ol>3M 468MP</ol>
      <ol>3M GPH - piankowa</ol>
      <li>taśma Lohmann dwustronna samoprzylepna</li>
      <ol>DuploColl 2742 LSE</ol>
      <ol>DuploColl 370</ol>
      <li>Taśmy ASTAT</li>
      <ol>Taśma MBN15/66 - piankowa czarna</ol>
      <ol>Taśma MBN290/66 - piankowa czarna</ol>
      <li>taśma piankowa 2009</li>
      <li>folia ochronna</li>
    </Listing>
  </>
);
