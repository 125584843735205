import styled, { css } from 'styled-components';

import { PageHeading as PageHeadingBase, HeadingSubtext as HeadingSubtextBase } from 'components/Typography';

const Container = styled.main(
  ({ theme }) => css`
    align-self: center;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing.big};
    width: 100%;

    ${theme.onScreen('SMALL_DESKTOP')} {
      padding: 0;
      width: 1180px;
    }
  `
);

const PageHeading = styled(PageHeadingBase)(
  ({ theme }) => css`
    ${theme.onScreen('SMALL_DESKTOP')} {
      margin-top: ${theme.spacing.huge};
    }
  `
);

const List = styled.nav(
  ({ theme }) => css`
    display: grid;
    grid-gap: ${theme.spacing.big};
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    margin-top: ${theme.spacing.big};
  `
);

const Listing = styled.ul(
  ({ theme }) => css`
    list-style: '- ';
    margin-top: ${theme.spacing.big};

    > h1 {
      margin-bottom: ${theme.spacing.mid};
      ${theme.font(16, 'SEMIBOLD')};
    }

    > li {
      margin-bottom: ${theme.spacing.mid};
      margin-left: ${theme.spacing.mid};
    }

    > ol {
      margin-bottom: ${theme.spacing.mid};
      margin-left: ${theme.spacing.huge};

      &:before {
        content: '- ';
      }
    }
  `
);

export const HeadingSubtext = styled(HeadingSubtextBase)`
  > a {
    color: ${({ theme }) => theme.color.blue[400]};
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const OfferElements = {
  Container,
  PageHeading,
  List,
  Listing,
  HeadingSubtext
};
