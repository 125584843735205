import React, { ChangeEvent } from 'react';
import styled, { css, InterpolationFunction, ThemeProps, DefaultTheme } from 'styled-components';

import { Input, useInput } from './Input';

type ChangeEventType = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

export interface InputProps {
  name: string;
  label: string;
  value?: string;
  type?: string;
  className?: string;
  onChange?: (e: ChangeEventType) => void;
  required?: boolean;
}

const activeLabel = css`
  color: ${({ theme }) => theme.color.blue[300]};
  transform: translate(0, -22px);
`;

const Wrapper = styled.div<{ isActive: boolean }>(
  ({ isActive }) => css`
    position: relative;
    width: 100%;

    &:focus-within {
      label {
        ${activeLabel}
      }
    }

    ${isActive
      ? css`
          label {
            ${activeLabel}
          }
        `
      : ''}
  `
);

const Label = styled.label(
  ({ theme }) => css`
    color: ${theme.color.gray[200]};
    cursor: text;
    font-size: 0.875rem;
    font-weight: 500;
    left: 0;
    position: absolute;
    transform: translate(${theme.spacing.mid}, ${theme.spacing.mid});
    transition: all 0.3s ease;
    z-index: -1;
  `
);

const commonStyles: InterpolationFunction<ThemeProps<DefaultTheme>> = ({ theme }) => css`
  background: rgba(0, 0, 0, 0.03);
  border: 0;
  color: ${theme.color.black};
  font-size: 1.125rem;
  padding-left: ${theme.spacing.mid};
  width: 100%;
`;

const StyledInput = styled(Input)`
  ${commonStyles};
`;

const StyledTextarea = styled.textarea(
  ({ theme }) => css`
    ${commonStyles}

    font-family: inherit;
    min-height: 250px;
    outline: none;
    padding-top: ${theme.spacing.mid};
    resize: none;
  `
);
type UseFormInput = (
  value: string,
  handler: (e: ChangeEventType) => void
) => {
  inputValue: string;
  isActive: boolean;
  onInputChange: (e: ChangeEventType) => void;
};

const useFormInput: UseFormInput = (value, handler) => {
  const input = useInput();
  const onInputChange = (e: ChangeEventType): void => {
    input.onChange(e);
    if (handler !== undefined) {
      handler(e);
    }
  };

  const isActive = value ? value.length > 0 : input.value.length > 0;

  return {
    inputValue: input.value,
    isActive,
    onInputChange
  };
};

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
export const LabeledInput: React.FC<InputProps> = ({ label, name, onChange, value, className, required, type }) => {
  const { inputValue, onInputChange, isActive } = useFormInput(value, onChange);

  return (
    <Wrapper className={className} isActive={isActive}>
      <Label htmlFor={name}>{label}</Label>
      <StyledInput id={name} type={type ?? 'text'} value={inputValue} onChange={onInputChange} required={required} />
    </Wrapper>
  );
};

export const LabeledTextarea: React.FC<InputProps> = ({ label, name, onChange, value, className, required }) => {
  const { inputValue, onInputChange, isActive } = useFormInput(value, onChange);

  return (
    <Wrapper className={className} isActive={isActive}>
      <Label htmlFor={name}>{label}</Label>
      <StyledTextarea id={name} value={inputValue} onChange={onInputChange} required={required} />
    </Wrapper>
  );
};
